// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AWS from "aws-sdk";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";
import { encryptRequestMiddleware } from "./encryptRequestMiddleware";
import { decryptResponseMiddleware } from "./decryptResponseMiddleware";
// import Resizer from "react-image-file-resizer";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};

const Axios = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVO,
});

Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  if (process.env.REACT_APP_ENCRYPTION === "false") {
    config.headers.Encryption = process.env.REACT_APP_ENCRYPTION;
  } else {
    const isFormData = config.headers['Content-Type'] === 'multipart/form-data';
    if (!isFormData && (config.method === 'put' || config.method === 'post' || config.method === 'patch') && config.data) {
      const encrypted = await encryptRequestMiddleware(config.data)
      config.data = encrypted;
    }
  }
  return config;
});


Axios.interceptors.response.use(
  response => {
    if (process.env.REACT_APP_ENCRYPTION === "false") {
      return response;
    }
    // Check content type
    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('application/json')) {
      response.data = JSON.parse(decryptResponseMiddleware(response.data));
    }

    return response;
  },
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      if (process.env.REACT_APP_ENCRYPTION === "false") {
        return error?.response;
      }
      // Check content type
      const contentType = error.response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        error.response.data = JSON.parse(decryptResponseMiddleware(error.response.data));
      }
      return error?.response
      // return Promise.reject(error?.response ?? error);
    }

  }
);

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVO,
});


AxiosInstance.interceptors.request.use(async (config) => {
  if (process.env.REACT_APP_ENCRYPTION === "false") {
    config.headers.Encryption = process.env.REACT_APP_ENCRYPTION; // for  encripttion  and decription mathod
  } else {
    const isFormData = config.headers['Content-Type'] === 'multipart/form-data';
    if (!isFormData && (config.method === 'put' || config.method === 'post' || config.method === 'patch') && config.data) {
      const encrypted = await encryptRequestMiddleware(config.data)
      config.data = encrypted;
    }
  }
  return config;
});
AxiosInstance.interceptors.response.use(
  response => {
    if (process.env.REACT_APP_ENCRYPTION === "false") {
      return response;
    }
    // Check content type
    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('application/json')) {
      response.data = JSON.parse(decryptResponseMiddleware(response.data));
    }

    return response;
  },
  error => {
    if (error.response.status === 401) {
    } else {
      if (process.env.REACT_APP_ENCRYPTION === "false") {
        return error?.response;
      }
      // Check content type
      const contentType = error.response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        error.response.data = JSON.parse(decryptResponseMiddleware(error.response.data));
      }
      return error?.response
      // return Promise.reject(error?.response ?? error);
    }

  }
);

export { Axios, AxiosInstance };

export const uploadImageToS3 = async (
  file,
  width,
  height,
  folderName,
  setisLoader
) => {
  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve("");
  }
  if (setisLoader) {
    setisLoader(true);
  }

  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, width, height, folderName, setisLoader)
      .then((data) => {
        const webp = data.replace(/\.[^/.]+$/, ".webp").replace("https://rhn-compression-bucket-v2.s3.ap-south-1.amazonaws.com/", "");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webp}`;

        setTimeout(() => {
          if (setisLoader) {
            setisLoader(false);
          }
          NotificationManager.success("File uploaded successfully", "Success");
          resolve(imgUrl); // Resolve after 8 seconds delay
        }, 10000);
      })
      .catch((error) => {
        if (setisLoader) {
          setisLoader(false);
        }
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};


export const uploadPdfToS3 = async (file, folderName, setIsLoader) => {
  const allowedExtensions = ["pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error("Only PDF file types are allowed", "Error");
    return Promise.resolve("");
  }

  // Start loader
  setIsLoader(true);

  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, null, null, folderName)
      .then((data) => {
        const webpLocation = data.replace("https://rhn-static-data-v2.s3.ap-south-1.amazonaws.com/", "");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;

        // Loader management and notification
        NotificationManager.success("File uploaded successfully", "Success");
        resolve(imgUrl);
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      })
      .finally(() => {
        // Stop loader
        setIsLoader(false);
      });
  });
};


export const uploadVideo = (file, setisLoader) => {
  const allowedExtensions = ["mp4", "mkv"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error("Only mp4, mkv file types are allowed", "Error");
    return Promise.resolve("");
  }

  setisLoader(true); // Start loader

  const folderName = "video";

  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, null, null, folderName, setisLoader)
      .then((data) => {
        const webp = data.replace("https://rhn-static-data-v2.s3.ap-south-1.amazonaws.com/", "");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webp}`;

        // Only success notification and resolve after URL generation
        NotificationManager.success("File uploaded successfully", "Success");
        resolve(imgUrl);
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload video", "Error");
        reject(error);
      })
      .finally(() => {
        setisLoader(false); // Stop loader regardless of success or failure
      });
  });
};


//-----------For Product master Description fields media upload (To set img url in func) -------------------

export const uploadImgToS3ForDesc = async (
  file,
  width,
  height,
  folderName,
  setisLoader,
  imgFun
) => {

  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve("");
  }

  if (setisLoader) {
    setisLoader(true);
  }

  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, width, height, folderName, setisLoader)
      .then((data) => {
        const webp = data.replace(/\.[^/.]+$/, ".webp").replace("https://rhn-compression-bucket-v2.s3.ap-south-1.amazonaws.com/", "");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webp}`;

        setTimeout(() => {
          if (setisLoader) {
            setisLoader(false);
          }
          if (imgFun) {
            imgFun(imgUrl);
          }
          NotificationManager.success("File uploaded successfully", "Success");
          resolve(imgUrl); // Resolve after 8 seconds delay
        }, 10000);
      })
      .catch((error) => {
        if (setisLoader) {
          setisLoader(false);
        }
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};


export const uploadVideoForDesc = (file, setisLoader, videoFun) => {
  const allowedExtensions = ["mp4", "mkv"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error("Only mp4, mkv file types are allowed", "Error");
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  setisLoader(true); // Start loader

  const folderName = "video";

  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, null, null, folderName, setisLoader)
      .then((data) => {
        const webp = data.replace("https://rhn-static-data-v2.s3.ap-south-1.amazonaws.com/", "");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webp}`;

        // Notify success and call videoFun immediately after URL generation
        NotificationManager.success("File uploaded successfully", "Success");
        videoFun(imgUrl); // Call videoFun with the generated URL
        resolve(imgUrl);
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload video", "Error");
        reject(error); 
      })
      .finally(() => {
        setisLoader(false); // Stop loader regardless of success or failure
      });
  });
};



// // Remaining
// export const deleteImageFromS3 = (e, filepath, setShowPreview) => {
//   // const bucketName = "relocare";
//   // const s3 = new AWS.S3({
//   //   apiVersion: "2006-03-01",
//   // });

//   const bucketName = "relocare";
//   const s3 = new AWS.S3({
//     apiVersion: "2012-10-17",
//   });
//   const params = {
//     Bucket: bucketName,
//     Key: filepath?.split("/")?.slice(3)?.join("/"),
//   };

//   s3.deleteObject(params, (err, data) => {
//     if (data) {
//       // Handle UI updates after successful deletion
//       setShowPreview(false);
//     } else {
//       console.log("Check if you have sufficient permissions : " + err);
//     }
//   });
// };


export const deleteImageFromS3 = async (e, filepath, setShowPreview) => {
  try {

    const s3UrlPrefix = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/`;
    const filePath = filepath.replace(s3UrlPrefix, "");
    const response = await Axios.post(`authorize/api/presigned/generate-presigned-url-for-delete`, {
      filePath: filePath,
    });

    if (response.data.status === false) {
      console.error("Failed to generate presigned URL for deletion");
      return "";
    } else {
      const presignedUrl = response.data.data;

      // Delete the file using the presigned URL
      const deleteResponse = await axios.delete(presignedUrl);

      if (deleteResponse.status === 204) {
        console.log("File deleted successfully");
        setShowPreview(false); // Update UI after successful deletion
        return deleteResponse;
      } else {
        console.error("Failed to delete file", deleteResponse);
        return "";
      }
    }
  } catch (error) {
    console.error("Error generating presigned URL or deleting file:", error);
    return "";
  }
};



export const UPLOAD_THUMBNAIL_IMAGE = async (
  event,
  setThumbnailFile,
  setShowPreviewThumbnail,
  setisLoader
) => {
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (["png", "jpg", "jpeg"].includes(extension)) {
    setisLoader(true);

    const today = new Date();
    const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    for (let i = 0; i < event.target.files.length; i++) {
      const imageFile = event.target.files[i];

      if (imageFile.size <= 1000000) {
        const reader = new FileReader();
        reader.onload = async function (e) {
          const img = new Image();
          img.onload = async function () {
            const width = this.width;
            const height = this.height;

            try {
              const fileUrl = await preSignedUploadImageToS3(imageFile, null, null, data.subfolderName, setisLoader);
              const webpLocation = fileUrl.replace(/\.[^/.]+$/, ".webp").replace("https://rhn-compression-bucket-v2.s3.ap-south-1.amazonaws.com/", "");
              const webpUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;

              NotificationManager.success("File uploaded successfully", "Success");
              setThumbnailFile(webpUrl);
              setShowPreviewThumbnail(true);
              setisLoader(false);
            } catch (err) {
              NotificationManager.error("Something went wrong!", "Error");
              setisLoader(false);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
      } else {
        dataArray.value = "";
        NotificationManager.error("File size needs to be less than or equal to 1mb", "File name: " + imageFile.name);
        setisLoader(false);
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error("Only JPG, PNG, or JPEG file types are allowed", "Error");
  }
};


function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  const allowedExtensions = ["mp4", "mov", "avi", "mkv"];
  if (!allowedExtensions.includes(extension)) {
    dataArray.value = ""; // Clear file input
    NotificationManager.error("Only video file types are allowed", "Error");
    return;
  }

  if (event.target.accept.includes("video/")) {
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    setLoading(true); // Show loader

    try {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];

        if (file.size <= 51000000) { // Check size <= 50 MB
          const presignedUrl = await preSignedUploadImageToS3(file, null, null, data.subfolderName, setLoading);
          const webpLocation = presignedUrl.replace("https://rhn-static-data-v2.s3.ap-south-1.amazonaws.com/", "");
          const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;

          NotificationManager.success("Video uploaded successfully", "Success");
          setMediaFile(imgUrl);
          setShowPreview(true);
        } else {
          dataArray.value = "";
          NotificationManager.error(
            "Video size needs to be less than or equal to 50 MB",
            "File name: " + file.name
          );
        }
      }
    } catch (err) {
      NotificationManager.error("Something went wrong!", "Error");
      console.error(err);
    } finally {
      setLoading(false); // Hide loader after processing all files
    }
  } else {
    dataArray.value = "";
    NotificationManager.error("Only video file types are allowed", "Error");
    setLoading(false); // Hide loader in case of invalid type
  }
};



// copy to URL to clipboard
export const copyToClipboard = (text, msg) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.success(`${msg} has been copied.`);
      })
      .catch((error) => {
        NotificationManager.error("Failed to copy");
      });
  } else {
    // Fallback for browsers that don't support Clipboard API
    try {
      const input = document.createElement("textarea");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      NotificationManager.success(`${msg} has been copied.`);
    } catch (error) {
      NotificationManager.error("Failed to copy");
    }
  }
};

export const convertTextToBoldAndItalic = (text) => {
  if (!text) return "";

  let formattedText = text;

  // Replace [b] and [/b] tags with <b> tags
  formattedText = formattedText
    .replace(/\[b\]/g, "<b>")
    .replace(/\[\/b\]/g, "</b>");

  // Replace [i] and [/i] tags with <i> tags
  formattedText = formattedText
    .replace(/\[i\]/g, "<i>")
    .replace(/\[\/i\]/g, "</i>");

  return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

//----------Restrict negative values or accept only number --------//
export const handleOnlyNumbers = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9) or a period (.)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Prevent period as the first character
  if (event.target.value === "" && charCode === 46) {
    event.preventDefault();
  }

  // Prevent multiple period characters
  if (charCode === 46 && event.target.value.includes(".")) {
    event.preventDefault();
  }
};

export const handleOnlyNumbersNoZero = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9) or a period (.)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Prevent period as the first character
  if (event.target.value === "" && charCode === 46) {
    event.preventDefault();
  }

  // Prevent multiple period characters
  if (charCode === 46 && event.target.value.includes(".")) {
    event.preventDefault();
  }

  // Prevent 0 as the first character
  if (event.target.value === "" && charCode === 48) {
    event.preventDefault();
  }
};


// Get presigned url
export const preSignedUploadImageToS3 = async (file, width, height, folderName, setisLoader) => {
  try {
    // Generate a 4-digit random number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);

    // Get current timestamp
    const timestamp = Date.now();
    const response = await Axios.post(`authorize/api/presigned/generate-presigned-url`, {
      filePath: `images/${folderName}/${timestamp}_${randomNumber}_${file.name}`,
      fileType: file.type,
    });

    if (response.data.status === false) {
      NotificationManager.error("Failed to upload file!");
      if (setisLoader) setisLoader(false);
      return "";
    }

    const presignedUrl = response.data.data;
    try {
      const uploadResponse = await axios.put(presignedUrl, file,   {
        headers: {
          'Content-Type': decodeURIComponent(file.type),
        },
      });

      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0];
        if (setisLoader) setisLoader(false);
        return fileUrl;
      } else {
        NotificationManager.error("Failed to upload file!");
        if (setisLoader) setisLoader(false);
        return "";
      }
    } catch (uploadError) {
      console.error("Error uploading file:", uploadError);
      NotificationManager.error("Failed to upload file!");
      if (setisLoader) setisLoader(false);
      return "";
    }
  } catch (error) {
    NotificationManager.error("Failed to generate presigned URL!");
    if (setisLoader) setisLoader(false);
    console.error("Error uploading file or generating presigned URL:", error);
    return "";
  }
};