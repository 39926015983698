
import { Axios, getUserData } from '../../util/index'
import { NotificationManager } from 'react-notifications';

const userData = getUserData();

export const getInformationList = (params, setisLoader) => {
    return async (dispatch) => {
        try {
            setisLoader(true);
            const response = await Axios.get(
                `/api/master/form-information/paginated`, 
                { 
                    params: {
                        page: params.page,
                        limit: params.perPage,
                        search: params.search
                    } 
                }
            );

            dispatch({
                type: 'GET_DATA_INFORMATION',
                allData: response.data.data.total,
                data: response.data.data.docs,
                totalPages: response.data.data.pages,
                params
            });
        } catch (error) {
            dispatch({
                type: 'GET_DATA_INFORMATION',
                allData: 0,
                data: [],
                totalPages: 0,
                params
            });
            
            NotificationManager.error(
                error.response?.data?.message || 'Failed to load data'
            );
        } finally {
            setisLoader(false);
        }
    }
}


//-----------------create new information--------------------------//
export const createNewInformation = (data, props, setisLoader, resetData) => {
    return (dispatch, getStore) => {
        
        Axios.post(`/api/master/form-information`, {
            title: data.title,
            video: data?.video || null,
            url: data.url,
            short_line_description: data.short_line_description,
            description: data.description,
            note: data.note,
            created_by: userData._id,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                }
                else {
                    props.onMasterSave();
                    resetData();
                    NotificationManager.success(response.data.message);
                    return dispatch => {
                        dispatch(
                            {
                                type: 'CREATE_INFORMATION',
                                data: []
                            }
                        )
                    }
                }
        

            })

            .then(() => dispatch(getInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');
            })
    }
}

// ------------------update information------------------------//
export const updateInformation = (data, props, setisLoader, resetData) => {
    return (dispatch, getStore) => {
        


        Axios.put(`api/master/form-information/${props.preloadValue.detail._id}`, {
            title: data.title,
            video: data?.video || null,
            url: data.url,
            short_line_description: data.short_line_description,
            description: data.description,
            note: data.note,
            created_by: userData._id,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                }
                else {
                    props.onMasterSave();
                    resetData();
                    NotificationManager.success(response.data.message);
                    return dispatch => {
                        dispatch(
                            {
                                type: 'UPDATE_INFORMATION',
                                data: []
                            }
                        )
                    }
                }
        

            })
            .then(() => dispatch(getInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');
            })
    }
}


